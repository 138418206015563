.blogImage {
	--gap: 32px;

	margin-bottom: 16px;
}

.image {
	display: flex;
	justify-content: center;
	position: relative;

	& img {
		max-width: 100%;
		height: auto;
	}
}

/* Size-specific */
.full {
	@media (--medium-up) {
		--gap: 0;
	}

	& .image {
		--image-margin: auto;

		width: 100%;
		height: auto;
		margin-left: var(--image-margin);
		margin-right: var(--image-margin);

		@media (--medium-up) {
			--image-margin: -40px;

			width: calc(100% + (-2 * var(--image-margin)));
		}

		@media (--large) {
			--image-margin: -89px;
		}
	}
}

.half {
	--width: 50%;
}

.third {
	--width: 33.33%;
}

/* position-specific */
.center {
	@media (--medium-up) {
		width: var(--width);
		margin-left: auto;
		margin-right: auto;
	}
}

.left,
.right {
	max-width: 100%;

	/* ! HACK: Aligns the top of the image with body text */
	padding-top: 8px;

	@media (--medium-up) {
		width: calc(var(--width) - var(--gap) / 2);
	}

	&.full .image {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

.left {
	@media (--medium-up) {
		float: left;
		margin-right: var(--gap);
	}
}

.right {
	@media (--medium-up) {
		float: right;
		margin-left: var(--gap);
	}
}

.lightboxButton {
	background: none;
	appearance: none;
	border: none;
	padding: 0;
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;

	&:hover {
		cursor: zoom-in;
	}
}

.caption {
	composes: g-type-body-small from global;
	color: var(--wpl-neutral-500);
	text-align: center;
	font-size: 14px;
}

.withCaption {
	margin-bottom: 0;
}
