.blogContentWrapper {
	--content-padding: 24px;

	margin: 88px auto;
	max-width: calc(736px + (2 * var(--content-padding)));
	padding: 0 var(--content-padding);

	@media (max-width: 975px) {
		margin: 48px auto;
		max-width: calc(574px + (2 * var(--content-padding)));
	}
}
