.root {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: var(--wpl-spacing-12) 0 var(--wpl-spacing-10) 0;

	@media (--medium-up) {
		padding: var(--wpl-spacing-13) 0 var(--wpl-spacing-10) 0;
	}

	@media (--large) {
		padding: var(--wpl-spacing-15) 0;
		max-width: min(100% - 24px, 1800px);
	}
}

.background {
	position: absolute;
	width: 100%;
	height: 100%;
	inset: 0;

	@nest .dark & {
		background-color: var(--wpl-neutral-900);
	}

	@media (--large) {
		border-radius: var(--wpl-spacing-01);
		overflow: hidden;
	}
}

.inner {
	composes: g-grid-container from global;
	composes: g-grid from global;
}

.text {
	margin-bottom: var(--wpl-spacing-10);
	grid-column: 1 / -1;

	@media (--large) {
		margin-bottom: 114px;
		grid-column: 1 / span 6;
	}
}

.sets {
	grid-column: 1 / -1;
	display: grid;
	gap: var(--wpl-spacing-13);
}

.relatedContentText {
	margin-bottom: var(--wpl-spacing-05);

	@media (--medium) {
		margin-bottom: var(--wpl-spacing-06);
	}
}

.cards {
	--columns: 1;

	grid-column: 1 / -1;
	display: grid;
	grid-gap: var(--wpl-spacing-06);
	grid-template-columns: repeat(var(--columns), 1fr);
	margin-bottom: var(--wpl-spacing-06);

	@media (--medium) {
		--columns: 2;
	}
	@media (--large) {
		--columns: 4;
	}
}

.cta {
	grid-column: 1 / -1;

	& a {
		font-weight: 500;
		font-size: 17px;
		line-height: 20px;
		letter-spacing: 0.0125em;
		text-decoration: underline;
		text-underline-offset: 3px;
		display: inline;

		@nest .dark & {
			color: var(--wpl-neutral-300);
		}
	}
}
