.headingWrapper {
	composes: g-grid-container from global;
	margin-bottom: var(--wpl-spacing-03);
}

.heading {
	width: 100%;

	@media (--large) {
		max-width: 592px;
		z-index: 1;
	}

	& em {
		color: var(--brand-link);
		font-style: normal;
	}
}

.detailsWrapper {
	composes: g-grid from global;
	composes: g-grid-container from global;

	@media (--large) {
		grid-template-rows: minmax(0, min-content);
		padding-top: 0;
		padding-bottom: 0;
	}
}

.description {
	grid-column: 1 / -1;
	grid-row: 1 / span 1;
	margin-bottom: var(--wpl-spacing-08);

	@media (--medium-up) {
		margin-bottom: var(--wpl-spacing-14);
	}

	@media (--large) {
		grid-column: 1 / span 5;
		margin-bottom: var(--wpl-spacing-06);
	}
}

.details {
	grid-column: 1 / -1;
	grid-row: 2 / span 1;
	padding: 0;
	margin: 0;
	width: 100%;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
	row-gap: var(--wpl-spacing-07);
	column-gap: var(--wpl-spacing-06);

	--columns: 2;

	@media (--medium-up) {
		column-gap: var(--wpl-spacing-08);
		grid-row: unset;
	}
	
	@media (--large) {
		grid-column: 7 / span 6;
  		grid-row: 1 / span 2;
		padding-top: 0;
		column-gap: var(--wpl-spacing-08);
	}
}

.imageTablet {
	display: none;
	
	@media (--medium-up) {
		display: flex;
		grid-column: 1 / -1;
		justify-content: center;
	}

	@media (--large) {
		display: none;
	}
	
	& img {
		max-width: 100%;
		height: auto;
	}
}


.imageDesktop {
	display: none;

	@media (--large) {
		display: block;
		grid-column: 1 / span 5;
		grid-row: 2 / span 1;
		position: relative;

		/* 
			In order to make the image overflow out of the grid, it's absolutely positioned and
			thus takes up no space on the page. In instances where the height of the text content to the 
			right of the image is less than the height of the image, the image will overflow into
			the next section. This `min-height` declaration is a brittle fix to prevent this.
		*/
		min-height: 375px;
	}
	
	& img {
		position: absolute;
		top: 0;
		right: 0;
		max-width: 150%;
		height: auto;
	}
}
