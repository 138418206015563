.dialogOverlay {
	background-color: rgba(0, 0, 0, 0.75);
	height: 100%;
	left: 0;
	overflow-y: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 666666667 /* higher than global nav */;
}

.dialogWrapper {
	display: grid;
	padding: 24px;
	place-items: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 666666668 /* higher than overlay */;
}

.dialogContent {
	background-color: var(--gray-1);
	color: var(--white);
	max-width: 800px;
	width: calc(90vw);
	outline: none;
	overflow-y: auto;
	padding: 24px;
	position: relative;

	@media (min-width: 768px) {
		padding: 48px;
	}
}

.dialogClose {
	appearance: none;
	background-color: transparent;
	border: 0;
	color: var(--white);
	composes: g-type-display-5 from global;
	cursor: pointer;
	margin: 0;
	padding: 0;
	position: absolute;
	right: 24px;
	top: 24px;
	z-index: 1;

	@media (min-width: 768px) {
		right: 48px;
		top: 48px;
	}

	@nest html[dir='rtl'] & {
		left: 24px;
		right: auto;

		@media (min-width: 768px) {
			left: 48px;
			right: auto;
		}
	}
}
