.dialogOverlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 666666668;
	background-color: rgba(255, 255, 255, 0.95);
	justify-content: center;
	align-items: center;
	composes: g-grid from global;
      
	&.dark {
		background-color: rgba(32, 33, 34, 0.9);
	}
}
      
.dialogContent {
	grid-column: 1 / -1;
	justify-self: center;
	max-width: 1200px;
	composes: g-grid-container from global;
	z-index: 666666669;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
      
.playerWrapper {
	position: relative;
      
	/* 
	  This padding-top attribute allows us to preserve a 16:9 aspect ratio. 
	  At the time of creating this, all videos will have a 16:9 aspect ratio. 
	  Should this change, we will want to rework how we handle aspect ratios.
	*/
	--aspect-ratio: 16 / 9;
      
	padding-top: calc(100% / (var(--aspect-ratio)));
}
      
.player {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: var(--wpl-spacing-01);
      
	@nest .dark & {
	  border: 1px solid var(--gray-2);
	}
}
      
.closeIconBttnWrapper {
	position: absolute;
	top: 25px;
	right: 25px;
}
      