.root {
	background: rgba(0, 0, 0, 0.03);
	width: 100%;
	margin: 0 auto;
	padding-top: var(--wpl-spacing-08);
	padding-bottom: var(--wpl-spacing-12);

	@media (--medium) {
		padding-top: var(--wpl-spacing-12);
	}

	@media (--large) {
		padding-top: var(--wpl-spacing-15);
		padding-bottom: var(--wpl-spacing-15);
		width: min(100% - 24px, 1800px);
		border-radius: 8px;
		overflow: hidden;
	}
}

.inner {
	composes: g-grid-container from global;
	composes: g-grid from global;
	row-gap: var(--wpl-spacing-07);

	@media (--medium-up) {
		row-gap: var(--wpl-spacing-08);
	}
}

.heading {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / 7;
	}
}

.workflow {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (--medium) {
		grid-column: 1 / 7;
	}

	@media (--large) {
		grid-column: 1 / 6;
	}

	&:last-child {
		@media (--medium) {
			grid-column: 7 / -1;
		}

		@media (--large) {
			grid-column: 8 / -1;
		}
	}
}

.logos {
	display: flex;
	flex-direction: row;
	gap: var(--wpl-spacing-05);
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	position: relative;

	@media (--medium) {
		gap: var(--wpl-spacing-03);
	}
}

.logo {
	max-height: 40px;
}
