.root {
	width: 100%;
	margin: 0 auto;
	padding-top: var(--wpl-spacing-07);
	padding-bottom: var(--wpl-spacing-12);
	
	@media (--medium) {
		padding-top: var(--wpl-spacing-12);
	}
	
	@media (--large) {
		padding-top: var(--wpl-spacing-15);
		padding-bottom: var(--wpl-spacing-14);
	}
}

.text {
	composes: g-grid-container from global;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--wpl-spacing-05);
	align-items: center;
	padding-bottom: var(--wpl-spacing-08);
	max-width: 800px;
	text-align: center;

	@media (--large) {
		padding-bottom: var(--wpl-spacing-14);
	}
}

.items {
	display: grid;
	row-gap: var(--wpl-spacing-11);
}
