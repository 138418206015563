.root {
	border-bottom: 1px solid var(--wpl-neutral-100);
}

.inner {
	composes: g-grid from global;
	composes: g-grid-container from global;
	padding-top: var(--wpl-spacing-08);
	padding-bottom: var(--wpl-spacing-12);

	@media (--medium-up) {
		padding-top: var(--wpl-spacing-12);
	}

	@media (--large) {
		padding-top: var(--wpl-spacing-15);
		padding-bottom: var(--wpl-spacing-15);
	}
}

.heading {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / 7;
	}
}

.useCases {
	padding: 0;
	margin: 0;
	list-style: none;
	grid-column: 1 / -1;
	composes: g-grid from global;
	row-gap: var(--wpl-spacing-06);

	@media (--medium) {
		row-gap: var(--wpl-spacing-07);
	}
}

.useCase {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: span 6;
	}

	@media (--large) {
		grid-column: span 4;
	}
}

.iconContainer {
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--brand-secondary);
	border-radius: 8px;
}

.ctaContainer {
	grid-column: 1 / -1;
	display: flex;
	justify-content: center;
	padding-top: var(--wpl-spacing-09);

	@media (--medium) {
		padding-top: var(--wpl-spacing-10);
	}

	@media (--large) {
		padding-top: var(--wpl-spacing-07);
	}
}
