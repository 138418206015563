.contentEnhanced {
	position: relative;
}

/* Blocks */
.imageWithCaption {
	margin: var(--hdsplus-spacing-08) 0;
}

.imageWithCaptionText {
	composes: g-type-body-small from global;
	margin-top: 12px;
	color: var(--wpl-neutral-500);

	@nest :global(.dark) & {
		color: var(--wpl-neutral-200);
	}
}
