.image {
	& img {
		width: 100%;
		height: auto;
		max-height: calc(100vh - 100px);
		max-width: calc(100vw - 100px);
		object-fit: contain;
	}
}

.dialogOverlay {
	z-index: 666666668;
	background-color: rgba(255, 255, 255, 0.95);
	position: fixed;
	inset: 0;
}

.dialogContent {
	z-index: 666666669;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	padding: 0 50px;
}

.closeIconBttnWrapper {
	position: fixed;
	top: 25px;
	right: 25px;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.95);
	border-radius: 4px;
}
