.root {
	padding-top: var(--wpl-spacing-07);

	@media (--medium) {
		padding-top: var(--wpl-spacing-12);
	}

	@media (--large) {
		padding-top: var(--wpl-spacing-15);
	}
}

.headingWrapper {
	composes: g-grid-container from global;
}

.items {
	width: 100%;
	list-style: none;
	margin: 0;
	padding-left: 0;
	display: grid;
	row-gap: var(--wpl-spacing-08);

	@media (--medium) {
		row-gap: var(--wpl-spacing-12);
	}
	@media (--large) {
		row-gap: var(--wpl-spacing-15);
	}
}

.item {
	max-width: 1600px;
	margin: 0 auto;
	width: 100%;
	display: grid;
	row-gap: var(--wpl-spacing-06);
}

.content {
	composes: g-grid-container from global;
	composes: g-grid from global;

	@media (--medium-up) {
		align-items: center;
		grid-area: 1 / 1;
	}
}

.contentInner {
	grid-column: 1 / -1;

	@media (--medium) {
		grid-column: 1 / span 6;
	}

	@media (--large) {
		grid-column: 1 / span 5;
	}
}

.imageContainer {
	width: 100%;
	display: flex;
	justify-content: center;

	@media (--medium-up) {
		grid-area: 1 / 1;
		justify-content: flex-end;
		padding-left: 50%;
	}

	@media (--large) {
		padding-right: var(--wpl-spacing-06);
	}
}

.image {
	max-width: 100%;
	height: auto;

	@media (--medium-up) {
		float: right;
	}
}

.contentVideoWrapper {
	display: none;
	padding-top: var(--wpl-spacing-05);

	@media (--large) {
		display: block;
	}
}
